import Fetcher from "@/services/Fetcher/Fetcher";

export type ActivePromoResponse = {
  id: number;
  description: string;
  coupon: string;
  condition: string;
  active: true;
  date_start: string;
  date_end: string;
  created_at: string;
  updated_at: string;
}[];

export const getActivePromo = async (): Promise<ActivePromoResponse> => {
  try {
    const result = await new Fetcher().get("/coupon-campaigns/active");
    return result.data;
  } catch (ex) {
    /* fetcher ja faz o processo de captura e log do erro, aqui e so pra nao quebrar nada ou lancar excption sem motivo */
  }
};
