<template>
  <section v-if="promo" class="w-full h-auto bg-no-repeat banner-promo">
    <div class="w-full flex items-center py-4">
      <div class="w-full flex flex-col items-center">
        <p class="h-full mx-auto text-center">
          {{ promo.description }}
          <span class="coupon-code bg-primary-default text-white" @click="copyCouponToClipboard">
            {{ promo.coupon }}
          </span>

          <i v-if="coping" class="copy-text text-md">copiado</i>
          <i v-else class="copy-icon uil uil-copy cursor-pointer" @click="copyCouponToClipboard" />
        </p>
        <p class="!text-[10px]">
          {{ promo.condition }}
        </p>
      </div>
      <div :class="['flex', 'items-center', styles.expire.container]">
        <p :class="['!text-base', 'text-center', styles.expire.date]">
          *válido até {{ moment(promo.date_end).format("DD/MM") }}
        </p>
        <button :class="['flex', 'justify-center', styles.expire.date]" @click="closePromo">
          <i class="uil uil-times" />
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import moment from "moment/moment";
import { onMounted, ref } from "vue";

import useFetcher from "@/hooks/useFetcher";
import useIsMobile from "@/hooks/useIsMobile";
import { getActivePromo } from "@/services/banner-promo";

const { isMobile } = useIsMobile();
const fetcherBanner = useFetcher(getActivePromo);

const promo = ref();
const coping = ref(false);

const styles = ref({
  expire: {
    container: "-ml-80",
    date: "lg:mr-2",
  },
});

async function fetchBanner() {
  await fetcherBanner.fetch();
  if (!fetcherBanner.isEmpty) {
    promo.value = fetcherBanner.response[0];
  }
}

function closePromo() {
  promo.value = undefined;
}

function copyCouponToClipboard() {
  coping.value = true;
  setTimeout(() => (coping.value = false), 2000);
  navigator.clipboard.writeText(promo.value.coupon);
}

onMounted(() => {
  fetchBanner();

  if (isMobile.value) {
    styles.value = {
      expire: {
        container: "",
        date: "lg:mr-4",
      },
    };
  }
});
</script>

<style scoped>
.banner-promo {
  display: flex;
  background-color: #d6d6d6;
}

.banner-promo p {
  font-size: 16px;
  font-weight: 600;
}

.banner-promo p span {
  margin: 0 10px;
  padding: 0 5px;
  display: inline-block;
  background-color: #ff4f00;
}

.coupon-code::selection {
  background-color: #00a4ff;
}
</style>
